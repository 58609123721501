import { isDev, isDevOrQA } from "./env";
export const devLog = (...what) => {
	if (isDev()) {

		// console.log('DEV LOG::', ...what);
		// console.log.call(console, 'DEV LOG::', ...what)
		// let l = console.log.bind(console, '---DEV LOG::');

		// getLogger()(...what);
		// logger.log(...what);
		// let e = (new Error)
		// var __LINE__ = e.stack.split("\n")[1].match(/:([0-9]+):/)[1];
		// console.log.apply(console, [' DEV LOG::', ...what])
		// let stackSpit = e.stack.split('\n');
		console.log('---DEV LOG::', ...what)
		// console.trace();

	}
}
export const devTime = (label) => {
	if (isDevOrQA()) {
		console.time(label);
		return () => {
			console.timeEnd(label);
		}
	} else {
		return () => { }
	}
}
export const devError = (...what) => {
	if (isDev()) {
		console.error('DEV Error::', ...what);
	}
}