import { postJSON as pj, getJSON as gj, postForm as pf } from 'otto_fetch';
import appMan from '../../shared/wui/appMan';
import { getRoot } from '../../shared/wui/utils';
import { devLog } from '../utils/log';
function handleError(err) {
    // appMan.showError(err.message);
    if (err.status && err.status == 403) {
        window.location.href = '/auth/login';
        return;
    }
}
export async function getJSON<T>(url) {
    try {
        let retVal = await gj<T>(url);
        return retVal;
    } catch (err) {
        handleError(err);
        // alert(err.message || err.error)
        // appManager.addError(err);
        throw err;
    }
}
export async function postJSON<T>(url, data) {
    try {
        return await pj<T>(url, data);
    } catch (err) {
        handleError(err);
        // appManager.addError(err);
        throw err;
    }
}
async function processResponse(res: Response) {
    let retVal;
    // if(res.status == 404){
    //     throw 404;
    // }
    let ct = res.headers.get('content-type');
    if (ct && ct.includes('application/json')) {
        try {
            retVal = await res.json();
        } catch (err) {
            console.error('error parsing response', err)
        }
        if (res.status != 200) {
            throw { ...retVal, status: res.status };
        }
        return retVal;
    }
}
export async function postForm<T = {}>(url, data) {
    // Default options are marked with *
    let res = await fetch(url, {
        body: data,
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, same-origin, *omit
        headers: {
            "Accept": "application/json"
        },
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        redirect: 'follow', // manual, *follow, error
        referrer: 'client', // *client, no-referrer
    });
    return await processResponse(res);


}
let root = getRoot();
//http://qa.lessonsinyourhome.net
let host = '';// (window as any).cordova ? 'http://qa.lessonsinyourhome.net' : '';
export function toUrl(ep){
    devLog(`${host}${root}${ep}`);
    
    if(root == '/'){
        return `${host}${ep}`;
    }else{
        return `${host}${root}${ep}`;
    }
}
export abstract class CDS {
    abstract endpoint: string;
}