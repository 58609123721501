import { LessonWizard } from "./components/lessonWizard";
import { CalendarComp } from "./components/calendar";
import { MessageCenter } from "./components/messageCenter";
import { Menu } from "./components/menu";
// import ds from "../../teacherApp/wui/ds";
import { User } from "../shared";
// import App from "../../teacherApp/wui/components/app";
import { routes as taRoutes } from '../../teacherApp/wui/routes';
import { routes as paRoutes } from '../../parentApp/wui/routes';
import { routes as boRoutes } from '../../backOffice/wui/routes'
import { routes as csRoutes} from '../../customersupport/wui/routes'
import { devLog } from "../utils/log";


class AppManager {
    menuComp: Menu;
    app: any;
    showLessonWizardToTab(lesson: any, tab, markedCB) {
        devLog('Show Lesson Wizard To TAb', lesson, tab, markedCB);
        this.lessonWizard.lesson = lesson;
        this.lessonWizard.selectedTab = tab;
        this.lessonWizard.open(markedCB);
    }
    showLessonWizard(lesson, markedCB) {
        devLog('Show Lesson Wizard', lesson, markedCB);
        if (lesson.data) {
            lesson = lesson.data;
        }
        this.lessonWizard.lesson = lesson;
        this.lessonWizard.selectedTab = 0;
        this.lessonWizard.open(markedCB);

    }
    messageCenter: MessageCenter;
    showMessage(message: string) {
        if (this.messageCenter) {
            this.messageCenter.showMessage(message);
        } else {
            alert(message);
        }
    }
    showError(error: string) {
        if (this.messageCenter) {
            this.messageCenter.showError(error);
        } else {
            alert(`Error: ${error}`);
        }

    }
    lessonWizard: LessonWizard;
    calendar: CalendarComp;

    refreshCal() {
        if (this.calendar) {
            this.calendar.refresh();
        }
    }
    onRouteChange(ev) {
        devLog(this)
        if (this.menuComp) {
            this.menuComp.close();
        }
    }

    private user: User;
    getUser() {
        if (window && (window as any).locals) {
            this.user = (window as any).locals.authenticatedUser;
            // devLog('User::', this.user);
            if ((window as any).ReactNativeWebView) {
                (window as any).ReactNativeWebView.postMessage(JSON.stringify({ type: 'SetUser', user: this.user }));
            }
        } else {
            return this.user;
        }
        return this.user;
    }
    getMenuItems() {
        if (window.location.href.includes('bo')) {
            return [
                { href: boRoutes.BackOffice.Dashboard, label: 'Home' },
                { href: boRoutes.BackOffice.Teachers, label: 'Teachers' },
                { href: boRoutes.BackOffice.Students, label: 'Students' },
                { href: boRoutes.BackOffice.Accounts, label: 'Families' },
                {
                    href: '#', label: 'Manage', menu: [
                        { href: boRoutes.BackOffice.Schools, label: 'Schools' }
                    ]
                }
            ];
        }
        if (window.location.href.includes('ta')) {
            return [
                { href: taRoutes.Teacher.Home, label: 'Home' },
                { href: taRoutes.Teacher.Calendar, label: 'Calendar' },
                { href: taRoutes.Teacher.Students, label: 'Students/Family' },
                { href: taRoutes.Teacher.Opportunities, label: 'Opportunities' },
                { href: taRoutes.Teacher.Lessons, label: 'Lessons' },
                { href: taRoutes.Teacher.FAQ, label: 'FAQ' },
                { href: taRoutes.Teacher.resources, label: 'Resources' },
                { href: taRoutes.Teacher.Profile, label: 'Profile' },
                { href: taRoutes.Teacher.support, label: 'Support' }
            ]
        }
        if (window.location.href.includes('pa')) {
            return [
                { href: paRoutes.home, label: 'Home' },
                { href: paRoutes.contactUs, label: 'Contact Us' },
                { href: paRoutes.calendar, label: 'Calendar' },
                { href: paRoutes.FAQ, label: 'FAQ' },
                { href: paRoutes.invoices, label: 'Account/Invoices' },
                { href: paRoutes.profile, label: 'Profile' },
                { href: paRoutes.support, label: 'Support' }
            ]
        }
        if (window.location.href.includes('cs')) {
            return [
                { href: csRoutes.CustomerService.Dashboard, label: 'Dashboard' },
                { href: csRoutes.CustomerService.Ticket_New, label: 'New Ticket' },
                { href: csRoutes.CustomerService.Tickets, label: 'Tickets' },
                { href: csRoutes.CustomerService.KnowledgeBase, label: 'Knowledge Base' },
            ]
        }
    }
    // async login(username, password) {
    //     let user = await ds.Auth.login(username, password);
    //     this.user = user;
    //     this.app.setState({ user: this.user });
    // }
    // async status() {
    //     let u = await ds.Auth.status();
    //     this.user = u;
    //     this.app.setState({ user: this.user })
    // }
}
export default new AppManager();