import { getRoot } from "../../shared/wui/utils";
const root = (window && (window as any).cordova) ? '/' : getRoot();
export const paths = {
    Home: root,
    Dashboard: root,
    Search: root + 'search',
    Students: root + 'students',
    AccountSearch: root + 'families',
    AccountView: root + 'families/:id',
    Student: root + 'students/:id',
    SchoolList: root + 'schools',
    SchoolForm: root + 'schools/:id',
    TeacherList: root + 'teachers',
    TeacherDetails: root + 'teachers/:id'

}
// export const profileTabs = {
//     Profile: 'p',
//     Background_Consent: 'bc',
//     Direct_Deposit: 'dd',
//     Pay_Records: 'pr',
//     Ten99s: '1099s',
//     Agreement: 'a',
//     Bio: 'b',
//     W9s: 'w9',
//     ProfilePic: 'pic'
// }
export const routes = {
    BackOffice: {
        Dashboard: root,
        Students: root + 'students',
        Accounts: root + 'families',
        Account_view: (id) => root + 'families/' + id,
        Schools: root + 'schools',
        Schools_edit: (id) => root + 'schools/' + id,

        Teachers: root + 'teachers',
        Teacher_Details: (id)=> root + 'teachers/' + id,
    }
}