import { devLog } from "../../shared/utils/log";
import { getRoot } from "../../shared/wui/utils";
const root = (window && (window as any).cordova) ? '/' : getRoot();
devLog('Root', root)
export const paths = {
    Home: root,
    Dashboard: root,
    Tickets: root + 'tickets',
    Ticket_New: root + 'tickets/new',
    Ticket_View: root + 'tickets/:id',
    KnowledgeBase: root + 'kb',

}
export const routes = {
    CustomerService: {
        Dashboard: root,
        Tickets: root + 'tickets',
        Ticket_New: root + 'tickets/new',
        Ticket_View: (id)=>(window as any).locals.csmp + `tickets/${id}`,
        KnowledgeBase:root+'kb'
    }
}