import { devLog } from "../../shared/utils/log";
import { getRoot } from "../../shared/wui/utils";

export const root = (window && (window as any).cordova) ? '/' : getRoot();

export const paths = {
	Home: root,
	contactUs: root + 'contactus',
	calendar: root + 'calendar',
	FAQ: root + 'faq',
	invoices: root + 'invoices',
	profile: root + 'profile',
	payNow: root + 'paynow',
	videoLesson: root + 'videoLesson/:sid',
	policiesAndProcedures: root + 'pp',
	support: root + 'cs',
	db2: root + 'db2',
	db1: root + 'db1'
}
export const routes = {
	home: root,
	contactUs: root + 'contactus',
	calendar: root + 'calendar',
	FAQ: root + 'faq',
	invoices: root + 'invoices',
	profile: root + 'profile',
	payNow: root + 'paynow',
	videoLesson: (sid) => root + `videoLesson/${sid}`,
	policiesAndProcedures: root + 'pp',
	support: root + 'cs'
}