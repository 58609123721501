export const isDev = () => {
    if (typeof window !== 'undefined') {
        if (!window.locals) {
            // todo: this proper
            return 'production';
        }
        return window.locals.settings.env == 'development';
    }
    if (process) {
        return process.env.NODE_ENV == 'development';
    }
}
export const isDevOrQA = () => {
    if (typeof window !== 'undefined') {
        if (!window.locals) {
            // todo: this proper
            return 'production';
        }
        return window.locals.settings.env == 'development' || window.locals.settings.env == 'qa';
    }
    if (process) {
        return process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'qa';
    }
}