// import { DateTime } from "luxon";
export function noop(e: any) {

}
import { DateTime } from "luxon";
import { Instructor, Roles, Ticket, User } from "../shared";
import { devLog } from "../utils/log";

export function getRoot() {
	let root = '/';
	if (window && (window as any).locals && window.location.href.includes('/ta')) {
		root = (window as any).locals.tamp;
		return root;
	}

	if (window && (window as any).locals && (window as any).locals.pamp && window.location.href.includes('/pa')) {
		root = (window as any).locals.pamp;
		return root;
	}
	if (window && (window as any).locals && window.location.href.includes('/cs')) {
		root = (window as any).locals.csmp;
		return root;
	}
	if (
		((window && (window as any).locals && (window as any).locals.bomp) && window.location.href.includes('/bo'))
		|| (window.location.pathname.includes('admin') || window.location.pathname.includes('director') || window.location.pathname.includes('da'))) {
		root = (window as any).locals.bomp;
		return root;
	}
	return root;
}
// /**
//  * 
//  * @param date 
//  * returns a date with the same time for today
//  */ this is poblematic because of going over midnight
// export function TimeToday(date: Date) {
// 	let today = new Date();
// 	return new Date(today.getFullYear(), today.getMonth(), today.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
// }
// export function TimeCompare(a, b, timezone) {
// 	debugger;
// 	let from = a
// 	if (typeof from == 'string') {
// 		from = new Date(from);
// 	}
// 	from = DateTime.from
// 	from = TimeToday(from);
// 	let to = b;
// 	if (typeof to == 'string') {
// 		to = new Date(to);
// 	}
// 	to = TimeToday(to)
// 	console.log(from, to, from.valueOf(), to.valueOf())
// 	return from > to;
// }
export function formatPhoneNumber(s: string) {
	// console.info('handle formatting better.')
	var s2 = ("" + s).replace(/\D/g, '');
	var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
	return (!m) ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
}
export function renderSSKBTemplate(template: string, ticket: Ticket, teachers: Instructor[]) {
	let opts: any = {
		contact: ticket.from,
		teacherList: teachers.map(x => `${x.fullName} - ${x.phones[0] ? formatPhoneNumber(x.phones[0].number) : x.email}`).join(', '),
		teacher: {
			name: { first: teachers.map(x => `${x.fullName} - ${x.phones[0] ? formatPhoneNumber(x.phones[0].number) : x.email}`).join(', ') },
			phone: { cell: '' }
		},
		director: ticket.from.school ? ticket.from.school : { name: { first: 'N/A' } },
		student: { name: { first: '' } }
	};
	opts.director.phoneNumber = formatPhoneNumber(opts.director.phones[0].number)
	return renderString(template, opts);
}
export function renderString(templateStr: string, params: Object) {
	const names = Object.keys(params);
	// @ts-ignore
	const vals = Object.values(params);
	return new Function(...names, `return \`${templateStr}\`;`)(...vals);
}
export function isMe(loggedInUser: User, data: User): boolean {
	return data.id === loggedInUser.id || data._id.toHexString() === loggedInUser._id.toString();
}
export function canView(loggedInUser: User, data: User): boolean {
	return hasRole(loggedInUser, Roles.Admin)
		|| isMe(loggedInUser, data);
}
export function hasRole(user: User, role: Roles) {
	if (!user) {
		return false;
	}
	return user.roles.includes(role);
}
export function isAdmin(user) {
	return hasRole(user, Roles.Admin);
}
export function isDirector(user: User) {
	return hasRole(user, Roles.SchoolDirector);
}
// Alias
export var isSchoolDirector = isDirector;
export function isAdminOrDirector(user) {
	return isDirector(user) || isAdmin(user);
}
export function isDirectorAssistant(user) {
	// devLog('isDirectorAssistant', user, Roles.DirectorAssistant);
	return hasRole(user, Roles.DirectorAssistant);
}
export function isDirectorOrDirectorAssistant(user) {
	return isDirector(user) || isDirectorAssistant(user);
}

export function isBackOffice(user: User) {
	return isAdminOrDirector(user) || isDirectorAssistant(user);
}
export function isTeacher(user: User) {
	return hasRole(user, Roles.Teacher);
}
export function isSharedServices(user: User) {
	return hasRole(user, Roles["Shared Services"]);
}
export function isParent(user: User) {
	return hasRole(user, Roles.Family);
}

import display, { createDateFromNumber, getDT } from "./components/display";
export namespace DateTimes {
	export function makeNumberFromDTISO(dtStr: string | Date, deflt) {

		// debugger;
		if (!dtStr) {
			return deflt;
		}

		let dt: DateTime = getDT(dtStr, window.locals.timezoneOverride);
		let retVal = Number.parseInt(dt.toFormat('HHmm'));
		devLog('makeNumberFromDTISO', retVal, dtStr);
		return retVal;
	};
	export function numberToTime(num, tz) {
		// debugger;
		let d = createDateFromNumber(num, tz);
		return display._t(d);
	}
}

export function lessontimeValid(lessonDate, duration, time = new Date()) {
	let a = getDT(lessonDate)?.setZone(window.locals.timezoneOverride, { keepLocalTime: true }) //.toJSDate().valueOf();
	let b = getDT(time).setZone(window.locals.timezoneOverride).minus({ minutes: duration }) //.toJSDate().valueOf();
	let valid = a <= b;

	devLog('DT::::::::::re:',
		a, a?.toJSDate(),
		// 'Now:::::', DateTime.local().toJSDate(),
		'Plus duration', b, b.toJSDate(),
		valid);
	return valid;
}