import { Component } from 'preact';
import { Text } from './text';
import { Select } from './select';
import { AllInputProps, InputNameCheckProps, InputProps } from './input';
import { h } from 'preact'
let usStates = [
    { name: 'Alabama', value: 'AL' },
    { name: 'Alaska', value: 'AK' },
    { name: 'Arizona', value: 'AZ' },
    { name: 'Arkansas', value: 'AR' },
    { name: 'California', value: 'CA' },
    { name: 'Colorado', value: 'CO' },
    { name: 'Connecticut', value: 'CT' },
    { name: 'Delaware', value: 'DE' },
    { name: 'District of Columbia', value: 'DC' },
    { name: 'Florida', value: 'FL' },
    { name: 'Georgia', value: 'GA' },
    { name: 'Hawaii', value: 'HI' },
    { name: 'Idaho', value: 'ID' },
    { name: 'Illinois', value: 'IL' },
    { name: 'Indiana', value: 'IN' },
    { name: 'Iowa', value: 'IA' },
    { name: 'Kansas', value: 'KS' },
    { name: 'Kentucky', value: 'KY' },
    { name: 'Louisiana', value: 'LA' },
    { name: 'Maine', value: 'ME' },
    { name: 'Maryland', value: 'MD' },
    { name: 'Massachusetts', value: 'MA' },
    { name: 'Michigan', value: 'MI' },
    { name: 'Minnesota', value: 'MN' },
    { name: 'Mississippi', value: 'MS' },
    { name: 'Missouri', value: 'MO' },
    { name: 'Montana', value: 'MT' },
    { name: 'Nebraska', value: 'NE' },
    { name: 'Nevada', value: 'NV' },
    { name: 'New Hampshire', value: 'NH' },
    { name: 'New Jersey', value: 'NJ' },
    { name: 'New Mexico', value: 'NM' },
    { name: 'New York', value: 'NY' },
    { name: 'North Carolina', value: 'NC' },
    { name: 'North Dakota', value: 'ND' },
    { name: 'Ohio', value: 'OH' },
    { name: 'Oklahoma', value: 'OK' },
    { name: 'Oregon', value: 'OR' },
    { name: 'Pennsylvania', value: 'PA' },
    { name: 'Rhode Island', value: 'RI' },
    { name: 'South Carolina', value: 'SC' },
    { name: 'South Dakota', value: 'SD' },
    { name: 'Tennessee', value: 'TN' },
    { name: 'Texas', value: 'TX' },
    { name: 'Utah', value: 'UT' },
    { name: 'Vermont', value: 'VT' },
    { name: 'Virginia', value: 'VA' },
    { name: 'Washington', value: 'WA' },
    { name: 'Washington, DC', value: 'DC' },
    { name: 'West Virginia', value: 'WV' },
    { name: 'Wisconsin', value: 'WI' },
    { name: 'Wyoming', value: 'WY' }
];
export class Address<T> extends Component<AllInputProps<InputNameCheckProps<T>>, any> {

    getNamePrefix() {
        let prefix = `${this.props.name ? this.props.name + '.' : ''}`;
        if (prefix == 'address.') {
            return prefix;
        }
        return prefix + 'address.';
    }
    render(props: any, state: any) {
        return <div class="address-block">
            <Text required={this.props.required} linkTo={this.props.linkTo} label="Street" name={`${this.getNamePrefix()}street`} inputClass="long" />
            <div class="multi-input">
                <Text required={this.props.required} linkTo={this.props.linkTo} label="City" name={`${this.getNamePrefix()}city`} />
                <Select addDefaultBlankToItems="State" required={this.props.required} items={([] as any).concat(usStates)} linkTo={this.props.linkTo} label="State" name={`${this.getNamePrefix()}state`} />
                <Text required={this.props.required} linkTo={this.props.linkTo} label="Zip" name={`${this.getNamePrefix()}zip`} inputClass="short" />
            </div>
        </div>;
    }
}