import { LoadingComponent } from "@emsquared/otto_preact_form";
import { ComponentChild } from "preact";
import { isAdmin } from "../utils";
import appMan from "../appMan";

export class LessonWizardComp extends LoadingComponent<{}, {loading:boolean}>{
    NAME = 'Lesson Wizard'
    async loadData(): Promise<void> {
    }
    shouldComponentUpdate(nextProps: Readonly<{ id: string; }>, nextState: Readonly<{ loading: boolean; } & { loading: boolean; }>, nextContext: any): boolean {
        if (isAdmin(appMan.getUser())) {
            return true;
        }
        // super.shouldComponentUpdate(nextProps, nextState, nextContext);
    }
    rrender(props?: {}, state?: Readonly<{ loading: boolean; }>, context?: any): ComponentChild {
        if (isAdmin(appMan.getUser())) {
            if (this.base && this.base.parentElement) {
                console.log(this.base.parentElement.innerHTML);
                if (this.base.parentElement.innerHTML != this.NAME) {
                    this.base.parentElement.innerHTML = this.NAME;
                }else{
                    return this.NAME;    
                }
                return
            }else{
                return this.NAME;
            }
        }
        return null;
    }
}