import 'idempotent-babel-polyfill';
import { h, render } from 'preact';
import { InfoWizardComp } from '../../apps/shared/wui/wizards/info';
import { devLog } from '../../apps/shared/utils/log';
import { LessonWizardComp } from '../../apps/shared/wui/wizards/lessons';
import { ScheduleWizardComp } from '../../apps/shared/wui/wizards/schedules';
class InfoWizardWC extends HTMLElement {

    constructor() {
        super();
        
    }
    connectedCallback() {
        let id = this.getAttribute('wid');
        devLog('WC connected', this, id);
        this.render();
    }
    render() {
        let id = this.getAttribute('wid');
        devLog('WC Render', this, id);
        return render(<InfoWizardComp id={id} />, this, this);
    }
}
customElements.define("info-wizard", InfoWizardWC);
class LessonWizardWC extends HTMLElement {

    constructor() {
        super();
        this.render();
    }
    render() {
        return render(<LessonWizardComp />, this, this);
    }
}
customElements.define("lesson-wizard", LessonWizardWC);
class ScheduleWizardWC extends HTMLElement {

    constructor() {
        super();
        this.render();
    }
    render() {
        return render(<ScheduleWizardComp />, this, this);
    }
}
customElements.define("schedule-wizard", ScheduleWizardWC);