import { getRoot } from "../../shared/wui/utils";
const root = (window && (window as any).cordova) ? '/' : getRoot();
export const paths = {
    Home: root,
    Dashboard: root,
    Calendar: root + 'calendar',
    Students: root + 'students',
    Opportunities: root + 'opportunities',
    Lessons: root + 'lessons',
    Profile: root + 'me',
    FAQ: root + 'faq',
    Student: root + 'students/:id',
    Account: root + 'family/:id',
    Opportunity: root + 'op/:id',
    videoLesson: root + 'videoLesson/:sid',
    resources: root + 'resources',
    support: root + 'cs',
    scheduleSurvey:root+'scsur/:id',

    db2: root + 'db2'
}
export const profileTabs = {
    Profile: 'p',
    Background_Consent: 'bc',
    Direct_Deposit: 'dd',
    Pay_Records: 'pr',
    Ten99s: '1099s',
    Agreement: 'a',
    Bio: 'b',
    W9s: 'w9',
    ProfilePic: 'pic',
    covidAcknowledgment: 'covidAcknowledgment'
}
export const routes = {
    Teacher: {
        Home: root,
        Dashboard: root,
        Calendar: root + 'calendar',
        Students: root + 'students',
        Opportunities: root + 'opportunities',
        Opportunity: (id) => root + `op/${id}`,
        Lessons: root + 'lessons',
        ProfilePage: root + 'me',
        FAQ: root + 'faq',
        Student: (id) => root + `students/${id}`,
        Account: (id) => root + `family/${id}`,
        Profile: root + `me`,
        Background_Consent: root + `me?t=${profileTabs.Background_Consent}`,
        Direct_Deposit: root + `me?t=${profileTabs.Direct_Deposit}`,
        Pay_Records: root + `me?t=${profileTabs.Pay_Records}`,
        Ten99s: root + `me?t=${profileTabs.Ten99s}`,
        Agreement: root + `me?t=${profileTabs.Agreement}`,
        Bio: root + `me?t=${profileTabs.Bio}`,
        W9s: root + `me?t=${profileTabs.W9s}`,
        ProfilePic: root + `me?t=${profileTabs.ProfilePic}`,
        covidAcknowledgment: root + `me?t=${profileTabs.covidAcknowledgment}`,
        videoLesson: (sid) => root + `videoLesson/${sid}`,
        resources: root + 'resources',
        support: root+'cs'

    }
}